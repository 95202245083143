import { graphql } from "gatsby";
import React from "react";

import languages from "~/languages";
import MinPageHeightWrapper from "Components/MinPageHeightWrapper";
import SEO from "Components/SEO";
import Wrapper from "Components/Wrapper";
import styling from "Services/styling";

const Styles = {
	wrapper: {
		alignItems: "start",
		"& > *": {
			width: "100%",
		},
	},
	paddingWrapper: {
		position: "relative",
		zIndex: 1,
		...styling.mixins.padding(0, 20),
		[styling.sizes.breakpoints.medium]: {
			...styling.mixins.padding(0, 60),
		},
		p: {
			fontSize: "1.4rem",
			lineHeight: "1.8rem",
		},
	},
	heading: {
		fontSize: "5rem",
		lineHeight: "5rem",
		fontWeight: 700,
		position: "relative",
		zIndex: 1,
		...styling.mixins.margin(0, 0, 30),
		[styling.sizes.breakpoints.small]: {
			fontSize: "7rem",
			lineHeight: "7rem",
		},
		[styling.sizes.breakpoints.medium]: {
			width: "50%",
			fontSize: "10rem",
			lineHeight: "10rem",
			letterSpacing: "-0.15rem",
		},
	},
};

const NotFoundPage = ({
	data: { header, footer, legal, loginResorts, products },
	pageContext: { langKey },
}) => (
	<Wrapper
		lang={langKey || languages.defaultLangKey}
		footer={footer}
		header={header}
		legal={legal.edges}
		loginResorts={loginResorts.edges}
		pageType="404"
		products={products.edges}>
		<SEO title="404: Not found" />
		<MinPageHeightWrapper style={Styles.wrapper}>
			<div css={Styles.paddingWrapper}>
				<h2 css={Styles.heading}>Page not found</h2>
				<p>You just hit a route that doesn’t exist... the sadness.</p>
			</div>
		</MinPageHeightWrapper>
	</Wrapper>
);

export default NotFoundPage;

export const query = graphql`
	query fourOhFourPageQuery($langKey: String) {
		header: contentfulHeader(node_locale: { eq: $langKey }) {
			...HeaderQuery
		}
		footer: contentfulFooter(node_locale: { eq: $langKey }) {
			...FooterQuery
		}
		loginResorts: allContentfulResortLogin(
			sort: { fields: title }
			filter: { node_locale: { eq: $langKey } }
		) {
			edges {
				node {
					slug
					title
				}
			}
		}
		products: allContentfulProduct(
			filter: { node_locale: { eq: $langKey } }
			sort: { fields: orderHint }
		) {
			edges {
				node {
					...ProductsMenuQuery
				}
			}
		}
		legal: allContentfulPagePlain(
			filter: { node_locale: { eq: $langKey } }
			sort: { fields: slug }
		) {
			edges {
				node {
					...PlainPagesMenuQuery
				}
			}
		}
	}
`;
